<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container" :style="'background: url('+bannerBg+')'">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">VP6000/VP7000系列</h1>
        <p data-caption-delay="100" class="wow fadeInRight">高性能智能数据记录仪</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
          <div>立即咨询</div>
        </div>
      </div>
    </div>
    <div class="all">
      <div class="product-VP67-background wow fadeInLeft">
        <div class="product-VP67 container">
          <el-row>
            <el-col :span="24" >
              <div class="headquarters">产品介绍</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" class="product-details">
              <p class="first-details indent">
                VP6000/VP7000系列是针对汽车应用环境设计的高性能、高鲁棒性的硬件平台，用于总线数据、视频流、雷达原始数据、XCP on Ethernet数据的并行同步记录，极高的写入带宽（单机500MByte/s或1GByte/s）以及可替换的大容量硬盘，丰富的产品变形，使得VP系列非常适合ADAS和高级自动驾驶相关应用。
              </p>
              <p class="indent">
                Smart Logger是由VP6000/VP7000和CANape log构成的智能数据记录系统，适用于大带宽情况下的批量数据采集的应用场景。
              </p>
              <p class="indent">
                CANape log是CANape 的独立运行模式，运行时没有CANape的人机交互界面，所需的配置工程由PC端CANape创建，并下载到VP6000/VP7000系列中。记录过程中Smart logger无需连接PC端的CANape，即可根据预设条件自动实现数据测量和记录。
              </p>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="core-component-background wow fadeInRight">
        <div class="core-component container">
          <el-row>
            <el-col :span="24" >
              <div class="headquarters">核心组件</div>
            </el-col>
          </el-row>
          <el-row class="core-component-table">
            <el-table
              :data="tableData"
              :span-method="arraySpanMethod"
              :header-cell-style="{background:'#296CA8',color:'#FFFFFF'}"
              size="small"
              style="font-size: 14px"
              border>
              <el-table-column
                prop="title"
                label=""
                :width="handleTableColumnWidth().column1">
              </el-table-column>
              <el-table-column
                prop="version1"
                label="VP6400"
                :width="handleTableColumnWidth().column2">
              </el-table-column>
              <el-table-column
                prop="version2"
                label="VP7400"
                :width="handleTableColumnWidth().column3">
                <template slot-scope="scope">
                  <!-- 使用 v-html 渲染 HTML 内容 -->
                  <div v-html="scope.row.version2"></div>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </div>
      </div>
      <div class="system-image wow fadeInLeft">
        <div class="system container">
          <el-row>
            <el-col :span="24" >
              <div class="headquarters">产品图片</div>
            </el-col>
          </el-row>
          <div class="content">
            <el-row>
              <el-col :xs="8" :sm="8" :md="8" :lg="3" :xl="3">
                <p class="text-VP6000">VP6000 系列</p>
              </el-col>
              <el-col :xs="16" :sm="16" :md="16" :lg="9" :xl="9">
                <img src="../../../assets/images/VP6000-image.jpeg" alt="" class="image-VP6000">
              </el-col>
              <el-col :xs="16" :sm="16" :md="16" :lg="9" :xl="9">
                <img src="../../../assets/images/VP7000-image.jpeg" alt="" class="image-VP7000">
              </el-col>
              <el-col :xs="8" :sm="8" :md="8" :lg="3" :xl="3">
                <p class="text-VP7000">VP7000 系列</p>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <bzInfo style="margin-top: 0"/>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue";
import mainFooter from "@/components/MainFooter.vue";
import "@/style/vp67.less";
import bzInfo from "@/components/BzInfo.vue";
import bannerBg from '@/assets/images/banner-product.jpg';
export default {
  name: "vp67",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data() {
    return{
      activeIndex: '3-1',
      bannerBg: bannerBg,
      tableData: [
        {
          title:"处理器",
          version1: "Intel Core i3 8100H (4*2.6GHz)",
          version2:"Intel Core i7 6820EQ (4*2.8GHz)"
        },{
          title:"内存",
          version1: "16G",
          version2:"32G"
        },{
          title:"可替换固态硬盘",
          version1: "容量可选，最高4T",
          version2:"容量可选，最高16T"
        },{
          title:"记录带宽",
          version1: "500MByte/s",
          version2:"1GByte/s"
        },{
          title:"接口",
          version1: "2x1Gbit/s ETH" ,
          version2:"6x1Gbit/s ETH"
        },{
          title:"接口",
          version1: "2x10Gbit/s ETH" ,
          version2:"2x10Gbit/s ETH"
        },{
          title:"接口",
          version1: "1x1Gbit/s ETH (MGMT)",
          version2:"1x1Gbit/s ETH (MGMT)"
        },{
          title:"接口",
          version1: "3xUSB3.1" ,
          version2:"4xUSB3.0" + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + "GPIO"
        },{
          title:"总线接口",
          version1: "总线接口可以灵活配置CAN/CAN FD/FlexRay/LIN等总线类型和通道数量",
          version2:"总线接口可以灵活配置CAN/CAN FD/FlexRay/LIN等总线类型和通道数量"
        },{
          title:"时间同步",
          version1: "PTP\n" +
            "GNSS-Based UTC-time\n" +
            "Vector硬件同步\n",
          version2:"PTP\n" +
            "GNSS-Based UTC-time\n"
        },{
          title:"尺寸",
          version1: "216 x 84 x 250 (19\"/2)",
          version2:"VP7440: 320 x 66 x 250\n" +
            "VP7470: 320 x 110 x 250\n"
        },{
          title:"温度范围",
          version1: "-20 °C…+70 °C（硬盘0 °C …+70 °C）",
          version2:"-20 °C…+70 °C（硬盘0 °C …+70 °C）"
        },
      ],
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted() {
    document.title = "VP6000/VP7000系列 - 产品中心 - 东信创智"
    new this.$wow.WOW().init()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 8) {
        if (columnIndex === 1) {
          // 将第一列向右合并一格
          return [1, 2];
        } else if (columnIndex === 2) {
          // 删除第二列
          return [0, 0];
        }
      }
      if (columnIndex === 0 && (rowIndex === 4 || rowIndex === 5 || rowIndex === 6 || rowIndex === 7)) {
        if (rowIndex % 4 === 0) {
          return {
            rowspan: 4,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    handleTableColumnWidth(){
      if (document.documentElement.clientWidth < 1100) {
        return {
          column1:null,
          column2:145,
          column3:145,
        }
      }else{
        return {
          column1:null,
          column2:450,
          column3:450,
        }
      }
    }
  },
}
</script>

<style scoped>

</style>
